
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({ name: "b-container" })
export default class BContainer extends Vue {
  @Prop(Boolean) readonly fluid!: boolean;

  get css(): Record<string, any> {
    return {
      fluid: this.fluid,
    };
  }
}
