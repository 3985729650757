
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({ name: "b-row" })
export default class BRow extends Vue {
  @Prop(String) readonly align!: string;

  get cssClass() {
    return {
      "align-items-center": this.align === "center",
    };
  }
}
