
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({ name: "b-col" })
export default class BCol extends Vue {
  @Prop([String, Number]) readonly xs!: string | number;
  @Prop([String, Number]) readonly sm!: string | number;
  @Prop([String, Number]) readonly md!: string | number;
  @Prop([String, Number]) readonly lg!: string | number;
  @Prop([String, Number]) readonly xl!: string | number;
  @Prop([String, Number]) readonly xxl!: string | number;

  get css(): Record<string, any> {
    const obCss = {};

    if (this.xs || this.sm || this.md || this.lg || this.xl || this.xxl) {
      if (this.xs) {
        this.$_.set(obCss, `col-${this.xs}`, true);
      }
      if (this.sm) {
        this.$_.set(obCss, `col-sm-${this.sm}`, true);
      }
      if (this.md) {
        this.$_.set(obCss, `col-md-${this.md}`, true);
      }
      if (this.lg) {
        this.$_.set(obCss, `col-lg-${this.lg}`, true);
      }
      if (this.xl) {
        this.$_.set(obCss, `col-xl-${this.xl}`, true);
      }
      if (this.xxl) {
        this.$_.set(obCss, `col-xxl-${this.xxl}`, true);
      }
    } else {
      this.$_.set(obCss, "col", true);
    }

    return obCss;
  }
}
