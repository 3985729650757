
import { Component, Prop, VModel, Vue } from "vue-property-decorator";
import CartTableItem from "@/modules/cart/components/CartTableItem.vue";
import { CartComponentItemsData } from "@planetadeleste/vue-mc-orders";

@Component({
  components: { CartTableItem },
})
export default class CartTable extends Vue {
  @VModel({ type: Object, default: () => ({}) }) items!: CartComponentItemsData;
  @Prop(Boolean) readonly readonly!: boolean;
}
