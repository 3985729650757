
import { Vue, Component, Prop } from "vue-property-decorator";
import BSection from "@/components/bs/BSection.vue";
import { Article, Category } from "@planetadeleste/vue-mc-goodnews";
import { AppModule } from "@/store/app";

@Component({
  components: { BSection },
})
export default class PageTitle extends Vue {
  obArticle: Article = new Article();

  @Prop(String) readonly background!: string;
  @Prop({ type: String, default: "top" }) readonly bgPosition!: string;
  @Prop(String) readonly title!: string;
  @Prop(String) readonly slug!: string;
  @Prop(Boolean) readonly overlay!: string;

  mounted(): void {
    this.$nextTick(this.loadArticle);
  }

  async loadArticle(): Promise<void> {
    if (!this.category || !this.slug) {
      return;
    }

    this.obArticle = new Article({
      slug: this.slug,
      category_id: this.category.id,
    });
    await this.obArticle.fetch();
  }

  get category(): Category | undefined {
    return AppModule.blogCategories.find({ slug: "pages" });
  }

  get sTitle(): string {
    return this.obArticle.title || this.title;
  }

  get sBackground(): string {
    return this.obArticle.preview_image || this.background;
  }

  get css(): Record<string, any> {
    const sBgPos = ["top", "center", "bottom"].includes(this.bgPosition)
      ? this.bgPosition
      : "top";

    return {
      "page__title-overlay": this.overlay,
      [`page__title-bg-${sBgPos}`]: true,
    };
  }
}
