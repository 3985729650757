var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.cartPosition.quantity && _vm.product && _vm.offer)?_c('tr',[_c('td',{staticClass:"product-thumbnail"},[_c('router-link',{attrs:{"to":{
        name: 'courses.view',
        params: { slug: _vm.product.slug },
      }}},[_c('img',{staticClass:"img-fluid",attrs:{"alt":_vm.product.name,"src":_vm.previewImage}})])],1),_c('td',{staticClass:"product-name"},[_c('router-link',{attrs:{"to":{
        name: 'courses.view',
        params: { slug: _vm.product.slug },
      }}},[_vm._v(" "+_vm._s(_vm.product.name)+" ")])],1),_c('td',{staticClass:"product-subtotal"},[_c('span',{staticClass:"amount"},[_vm._v(" "+_vm._s(_vm.cartPosition.currency)+" "+_vm._s(_vm.total)+" ")])]),(!_vm.readonly)?_c('td',{staticClass:"product-remove"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.stopPropagation();return _vm.removeItem.apply(null, arguments)}}},[_c('i',{staticClass:"fa fa-times"})])]):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }