
import { has, isEmpty, set } from "lodash";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({ name: "b-section" })
export default class BSection extends Vue {
  @Prop(Boolean) readonly fillHeight!: boolean;
  @Prop(Boolean) readonly relative!: boolean;
  @Prop(Boolean) readonly fixed!: boolean;
  @Prop(Boolean) readonly flex!: boolean;
  @Prop(Boolean) readonly dark!: boolean;
  @Prop(Boolean) readonly overlay!: boolean;
  @Prop(Boolean) readonly centerItems!: boolean;
  @Prop(String) readonly bgColor!: string;
  @Prop(String) readonly background!: string;
  @Prop(String) readonly title!: string;
  @Prop({ type: String, default: "hero" }) readonly area!: string;
  @Prop({ type: [String, Boolean], default: "4" }) readonly offset!:
    | string
    | number
    | boolean;

  get css(): Record<string, any> {
    const obCss = {
      section: true,
      fix: this.fixed,
      dark: this.dark,
      overlay: this.overlay,
      "p-relative": this.relative,
      "d-flex": this.flex || this.centerItems,
      "align-items-center": this.centerItems,
    };

    set(obCss, `${this.area}__area`, true);
    set(obCss, `${this.area}-area`, true);

    if (this.fillHeight) {
      set(obCss, `${this.area}__height`, true);
    }

    if (this.bgColor) {
      set(obCss, `${this.bgColor}-bg`, true);
    }

    return obCss;
  }

  get cssTitle(): Record<string, boolean> {
    const obCss = {};

    if (this.offset) {
      set(obCss, `offset-xxl-${this.offset}`, true);
    }

    return obCss;
  }

  get styles(): Record<string, any> | undefined {
    if (this.background) {
      return { backgroundImage: `url(${this.background})` };
    }

    return undefined;
  }

  get hasSlot(): boolean {
    return !isEmpty(this.$slots);
  }

  get hasTitleSlot(): boolean {
    return has(this.$slots, "title");
  }
}
