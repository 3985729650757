
import { Component, Mixins, Prop } from "vue-property-decorator";
import CartMixin from "@/modules/cart/mixins/Cart";
import { CartComponentPositionData } from "@planetadeleste/vue-mc-orders/src/types";
import {
  OfferData,
  ProductData,
} from "@planetadeleste/vue-mc-shopaholic/src/types";
import { CourseData } from "@planetadeleste/vue-mc-learning/src/types";
import { get, has, round } from "lodash";

@Component
export default class CartTableItem extends Mixins(CartMixin) {
  @Prop(Object) readonly cartPosition!: CartComponentPositionData;
  @Prop(Boolean) readonly readonly!: boolean;

  get total(): number {
    return this.cartPosition
      ? round(
          this.cartPosition.price_per_unit_value * this.cartPosition.quantity,
          2
        )
      : 0;
  }

  get product(): ProductData | null {
    return this.cartPosition && has(this.cartPosition, "product.id")
      ? this.cartPosition.product
      : null;
  }

  get course(): CourseData | null {
    return get(this.product, "course");
  }

  get offer(): OfferData | null {
    return this.cartPosition && has(this.cartPosition, "offer.id")
      ? this.cartPosition.offer
      : null;
  }

  get previewImage(): string | null {
    if (!this.product) {
      return null;
    }

    if (this.course && this.course.preview_image) {
      return this.course.preview_image;
    }

    if (
      !this.product.preview_image &&
      !this.product.images.length &&
      !this.product.thumbnail
    ) {
      return null;
    }

    return (
      this.product.preview_image ||
      this.product.thumbnail ||
      get(this.product.images, "0.path")
    );
  }

  get posProperty(): Record<string, any> {
    return get(this.cartPosition, "property", {});
  }

  async removeItem(): Promise<void> {
    if (!this.cart || !this.offer) {
      return;
    }

    await this.removeCart(this.offer.id);
  }
}
