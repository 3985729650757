
import { Component, Mixins, Watch } from "vue-property-decorator";
import CartMixin from "@/modules/cart/mixins/Cart";
import CartTable from "@/modules/cart/components/CartTable.vue";
import PageTitle from "@/components/common/PageTitle.vue";
import { CartComponentItemsData } from "@planetadeleste/vue-mc-orders";
import BSection from "@/components/bs/BSection.vue";
import BContainer from "@/components/bs/BContainer.vue";
import BRow from "@/components/bs/BRow.vue";
import BCol from "@/components/bs/BCol.vue";

@Component({
  components: {
    BCol,
    BRow,
    BContainer,
    BSection,
    PageTitle,
    CartTable,
  },
})
export default class CartIndex extends Mixins(CartMixin) {
  items: CartComponentItemsData | null = null;

  @Watch("cartItems", { immediate: true })
  onChangeCartItems(obItems: CartComponentItemsData) {
    this.items = this.$_.cloneDeep(obItems);
  }

  updateCartData() {
    if (!this.items) {
      return;
    }

    this.updateCart(this.toCartData(this.items));
  }
}
